<template>
  <div class="appHeader">
    <!-- 缓存标签 -->
    <div class="appHeader-cache">
      <el-scrollbar class="appHeader-cache-scroll">
        <div class="appHeader-cache-list">
          <div class="appHeader-cache-item" :class="{ 'appHeader-cache-item_active': $route.name == item.name }" v-for="item in tag" :key="item" @click="appHeaderRouter(item)">
            {{ item.title }}
            <div class="appHeader-cache-item-close" v-if="!item.default" @click.stop.capture="removeCache(item.name)">
              <app-icon name="deny" />
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "app-header",
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("app", ["menuSlide", "cache", "tag"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("user", ["logout", "getAuth"]),
    ...mapMutations("app", ["setMenuSlide", "removeCache"]),
    showMessage() {
      this.$message.warning("暂无通知");
    },
    appHeaderRouter(item) {
      this.$router.push({ name: item.name, params: { userCache: true } });
    },
  },
};
</script>

<style lang="scss" scoped>
.appHeader {
  background-color: color.$background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  &-button {
    width: 40px;
    height: 40px;
    // background-color: #f2f2f2;
    // color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-adminType {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-menuSlide {
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;

    &-icon {
      transition: all 250ms;
      transform: translateX(-100%);
    }

    &_show &-icon {
      transform: translateX(0);
    }
  }

  &-right {
    display: flex;
    gap: 10px;
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
  }

  &-cache {
    height: 100%;
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;

    &-scroll {
      width: 100%;
    }

    &-list {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 55px;
      flex-wrap: nowrap;
    }

    &-item {
      height: 32px;
      border-radius: 4px;
      padding: 0 10px;
      // padding-left: 10px;
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      font-size: 16px;
      color: color.$tableText;
      background: color.$tableBackground;
      white-space: nowrap;

      &_active {
        background: color.$sub;
      }

      &-close {
        margin-left: 10px;
        transition: 250ms;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -6px;

        &:hover {
          // filter: drop-shadow(0 0 2px rgba($color: #fff, $alpha: 0.5));
          // transform: rotate(360deg);

          background-color: lighten(color.$base, 10%);
          color: #fff;
        }
      }
    }
  }

  &-userInfo {
    display: flex;
    align-items: center;
    gap: 10px;

    &-name {
      font-size: 12px;
    }

    &-menu {
      text-align: center;

      &-item {
        cursor: pointer;
        height: 32px;
        line-height: 32px;

        :hover {
          color: color.$base;
        }
      }
    }
  }
}
</style>
