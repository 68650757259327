export default {
  // data() {
  //   return {
  //     dialogVisible: false,
  //     dialogParams: Object,
  //   }
  // },
  methods: {
    /**
     * 选择
     */
    select(type, e) {
      this.dialogVisible[type] = false;
      if (this[`${type}Select`]) this[`${type}Select`](e);
    },

    /**
     * 确认
     */
    confirm(type, e) {
      this.dialogVisible[type] = false;
      if (this[`${type}Confirm`]) this[`${type}Confirm`](e);
    },

    /**
     * 过滤器重置
     */
    filterReset(options = {}) {
      Object.entries(this.$options.data().filterData).forEach(([keys, value]) => {
        if (options.exception?.length) {
          if (!options.exception.includes(keys)) {
            this.$data.filterData[keys] = value;
          }
        } else {
          this.$data.filterData[keys] = value;
        }
      });
    },

    /**
     * 过滤器提交
     */
    filterSubmit() {
      // 表单验证
      this.$refs.filterForm.validate(async (valid) => {
        // 验证通过
        if (valid) {
          // 获取数据
          this.getData();
        }
      });
    },

    /**
     * 表单选中
     */
    tableSelect(e) {
      this.table.allCheck = e.length == this.table.list.length;
      this.table.selected = e;
    },

    /**
     * 页面跳转
     */
    pageChange(e) {
      // 设置页号
      this.table.page = e;

      // 获取数据
      this.getData();
    },

    /**
     * 全选
     */
    checkAll() {
      this.$refs["appTable-data"].toggleAllSelection();
    },

    /**
     * 打开弹窗
     */
    async openDialog(type, params) {
      this.dialogVisible[type] = true;
      if (params) this.dialogParams[type] = params;
    },

    /**
     * 关闭窗口
     */
    closeDialog(type) {
      this.dialogVisible[type] = false;
    },

    /**
     * 移除元素
     */
    async elementDel(ids = this.table.selected.map((item) => item.id)) {
      try {
        // 验证数据
        if (ids instanceof Array) this.$utils.check(ids.length, "请勾选元素");

        // 提示
        await this.$messageBox.confirm("确认删除?", "删除", {
          type: "warning",
        });

        // 显示加载
        const loading = this.$loading();

        try {
          // 发送请求
          await this.$request.delete(`${this.apiUrl.delete}/${ids}`);

          // 获取数据
          await this.getData();

          // 弹出提示
          this.$message.success("删除成功");
        } catch (e) {
          // 提示错误
          this.$message.error(e.message);
        } finally {
          // 关闭加载
          loading.close();
        }
      } catch (e) {
        // 提示错误
        if (e.message) this.$message.error(e.message);
      }
    },
    /**
     * 状态更改
     */
    async elementStatus(e, status) {
      try {
        // 验证数据
        if (e instanceof Array) this.$utils.check(e.length, "请勾选元素");

        // 显示加载
        const loading = this.$loading();

        try {
          if (e instanceof Array) {
            // 发送请求
            await this.$request.put(`${this.apiUrl.status}/${e.map((item) => item.id)}/${status}`);
          } else {
            // 发送请求
            await this.$request.put(`${this.apiUrl.status}/${e.id}/${e.status ^ 3}`);
          }

          // 获取数据
          await this.getData();

          // 弹出提示
          this.$message.success("修改成功");
        } catch (e) {
          // 提示错误
          this.$message.error(e.message);
        } finally {
          // 关闭加载
          loading.close();
        }
      } catch (e) {
        // 提示错误
        if (e.message) this.$message.error(e.message);
      }
    },

    /**
     * 选中元素
     */
    elementSelect(e = this.table.selected) {
      // 验证数据
      if (e instanceof Array) {
        this.$utils.check(e.length, "请勾选元素");
      } else {
        e = [e];
      }

      // 弹出选择
      this.$emit("select", e);

      // 关闭弹窗
      this.$refs.dialog.close();
    },
  },
};
