<template>
  <div class="appDescriptions">
    <div class="appDescriptions-header" v-if="title">
      <div>{{ title }}</div>
      <div class="right" v-if="right" @click="isShow=!isShow">{{ isShow?'收起':'展开' }}</div>
    </div>
    <div class="appDescriptions-content" v-show="isShow">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    right:{
      default: '',
      type: String,
    },
    defaultShow:{
      default: true,
      type: Boolean,
    },
    column: {
      default: 2,
      type: Number,
    },
  },
  data() {
    return {
      isShow:true,
    };
  },
  mounted() {
      this.isShow = this.defaultShow
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.appDescriptions {
  // padding: 10px 60px;
  margin-top: 20px;

  &-header {
    color: color.$base;
    border-bottom: 1px dashed color.$base;
    padding-bottom: 10px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    .right{
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;

    ::v-deep(.appDescriptionsItem) {
      // width: calc(100% / v-bind(column));
      flex: 1 0 calc(100% / v-bind(column));
    }
  }
}
</style>