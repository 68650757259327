// 默认路由
const defaultRouter = {
  path: "home",
  name: "general.home",
  component: () => import("@/views/general/home/index.vue"),
  meta: {
    title: "首页",
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: "supplier",
      name: "general.supplier",
      component: () => import("@/views/general/supplier/index.vue"),
      meta: {
        title: "供应商管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "scenic",
      name: "general.scenic",
      component: () => import("@/views/general/scenic/index.vue"),
      meta: {
        title: "景区管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "hotel",
      name: "general.hotel",
      component: () => import("@/views/general/hotel/index.vue"),
      meta: {
        title: "酒店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "venue",
      name: "general.venue",
      component: () => import("@/views/general/venue/index.vue"),
      meta: {
        title: "场馆管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributor",
      name: "general.distributor",
      component: () => import("@/views/general/distributor/index.vue"),
      meta: {
        title: "分销商管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "admin",
      name: "general.admin",
      component: () => import("@/views/general/admin/index.vue"),
      meta: {
        title: "管理员管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "menuTree",
      name: "general.menuTree",
      component: () => import("@/views/general/menuTree/index.vue"),
      meta: {
        title: "菜单树管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "journal",
      name: "general.journal",
      component: () => import("@/views/general/journal/index.vue"),
      meta: {
        title: "操作日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "loginLog",
      name: "general.loginLog",
      component: () => import("@/views/general/loginLog/index.vue"),
      meta: {
        title: "登录日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "roleGroup",
      name: "general.roleGroup",
      component: () => import("@/views/general/roleGroup/index.vue"),
      meta: {
        title: "角色组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierAudit",
      name: "general.supplierAudit",
      component: () => import("@/views/general/supplierAudit/index.vue"),
      meta: {
        title: "供应商审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorAudit",
      name: "general.distributorAudit",
      component: () => import("@/views/general/distributorAudit/index.vue"),
      meta: {
        title: "分销商审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPending",
      name: "general.checkPending",
      component: () => import("@/views/general/checkPending/index.vue"),
      meta: {
        title: "待审核列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "corpCheck",
      name: "general.corpCheck",
      component: () => import("@/views/general/corpCheck/index.vue"),
      meta: {
        title: "企业信息变更申请",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "ticketDocking",
      name: "general.ticketDocking",
      component: () => import("@/views/general/ticketDocking/index.vue"),
      meta: {
        title: "票务系统对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsTicket",
      name: "general.productsTicket",
      component: () => import("@/views/general/productsTicket/index.vue"),
      meta: {
        title: "门票产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsCombination",
      name: "general.productsCombination",
      component: () => import("@/views/general/productsCombination/index.vue"),
      meta: {
        title: "组合商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsFleet",
      name: "general.productsFleet",
      component: () => import("@/views/general/productsFleet/index.vue"),
      meta: {
        title: "车队管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsHotel",
      name: "general.productsHotel",
      component: () => import("@/views/general/productsHotel/index.vue"),
      meta: {
        title: "酒店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsLine",
      name: "general.productsLine",
      component: () => import("@/views/general/productsLine/index.vue"),
      meta: {
        title: "线路管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsRestaurant",
      name: "general.productsRestaurant",
      component: () => import("@/views/general/productsRestaurant/index.vue"),
      meta: {
        title: "餐饮管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productsSpecialty",
      name: "general.productsSpecialty",
      component: () => import("@/views/general/productsSpecialty/index.vue"),
      meta: {
        title: "特产管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundTicket",
      name: "general.refundTicket",
      component: () => import("@/views/general/refundTicket/index.vue"),
      meta: {
        title: "退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPendingRefundTicket",
      name: "general.checkPendingRefundTicket",
      component: () => import("@/views/general/checkPendingRefundTicket/index.vue"),
      meta: {
        title: "待审核退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldOrder",
      name: "general.soldOrder",
      component: () => import("@/views/general/soldOrder/index.vue"),
      meta: {
        title: "已售订单列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldOrderCard",
      name: "general.soldOrderCard",
      component: () => import("@/views/general/soldOrderCard/index.vue"),
      meta: {
        title: "卡订单列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccount",
      name: "general.creditAccount",
      component: () => import("@/views/general/creditAccount/index.vue"),
      meta: {
        title: "备用金账户信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccountRecord",
      name: "general.creditAccountRecord",
      component: () => import("@/views/general/creditAccountRecord/index.vue"),
      meta: {
        title: "备用金资金记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierAccount",
      name: "general.supplierAccount",
      component: () => import("@/views/general/supplierAccount/index.vue"),
      meta: {
        title: "平台账户信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierMoneyRecord",
      name: "general.supplierMoneyRecord",
      component: () => import("@/views/general/supplierMoneyRecord/index.vue"),
      meta: {
        title: "平台资金记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "alreadyMessage",
      name: "general.alreadyMessage",
      component: () => import("@/views/general/alreadyMessage/index.vue"),
      meta: {
        title: "已发消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "broadcastMessage",
      name: "general.broadcastMessage",
      component: () => import("@/views/general/broadcastMessage/index.vue"),
      meta: {
        title: "广播消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "msgTemplate",
      name: "general.msgTemplate",
      component: () => import("@/views/general/msgTemplate/index.vue"),
      meta: {
        title: "消息组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorDocking",
      name: "general.distributorDocking",
      component: () => import("@/views/general/distributorDocking/index.vue"),
      meta: {
        title: "分销商对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "receivedMessage",
      name: "general.receivedMessage",
      component: () => import("@/views/general/receivedMessage/index.vue"),
      meta: {
        title: "已收消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorConfig",
      name: "general.distributorConfig",
      component: () => import("@/views/general/distributorConfig/index.vue"),
      meta: {
        title: "补贴配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "foodScenic",
      name: "general.foodScenic",
      component: () => import("@/views/general/foodScenic/index.vue"),
      meta: {
        title: "餐饮门店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "shopMange",
      name: "general.shopMange",
      component: () => import("@/views/general/shopMange/index.vue"),
      meta: {
        title: "购物店门店管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "yongyouConfig",
      name: "general.yongyouConfig",
      component: () => import("@/views/general/yongyouConfig/index.vue"),
      meta: {
        title: "用友对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "componentManage",
      name: "general.componentManage",
      component: () => import("@/views/general/componentManage/index.vue"),
      meta: {
        title: "评论管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "nationalDistribution",
      name: "general.distributionConfig",
      component: () => import("@/views/general/distributionConfig/index.vue"),
      meta: {
        title: "分销配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelVendors",
      name: "general.channelVendors",
      component: () => import("@/views/general/channelVendors/index.vue"),
      meta: {
        title: "分销配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelMerchant",
      name: "general.channelMerchant",
      component: () => import("@/views/general/channelMerchant/index.vue"),
      meta: {
        title: "渠道商管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributionGoods",
      name: "general.distributionGoods",
      component: () => import("@/views/general/distributionGoods/index.vue"),
      meta: {
        title: "分销商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributionOrders",
      name: "general.distributionOrders",
      component: () => import("@/views/general/distributionOrders/index.vue"),
      meta: {
        title: "分销订单管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "withdrawalSetup",
      name: "general.withdrawalSetup",
      component: () => import("@/views/general/withdrawalSetup/index.vue"),
      meta: {
        title: "提现配置管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "accountsAdmin",
      name: "general.accountsAdmin",
      component: () => import("@/views/general/accountsAdmin/index.vue"),
      meta: {
        title: "帐号重置登陆次数",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "venueClassification",
      name: "general.venueClassification",
      component: () => import("@/views/general/venueClassification/index.vue"),
      meta: {
        title: "场馆分类",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "promotion",
      name: "general.promotion",
      component: () => import("@/views/general/promotion/index.vue"),
      meta: {
        title: "活动管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "uniappShinyZunyi",
      name: "general.uniappShinyZunyi",
      component: () => import("@/views/general/uniappShinyZunyi/index.vue"),
      meta: {
        title: "活动管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "comment",
      name: "general.comment",
      component: () => import("@/views/general/comment/index.vue"),
      meta: {
        title: "评论列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "waterCard",
      name: "general.waterCard",
      component: () => import("@/views/general/waterCard/index.vue"),
      meta: {
        title: "亲水季会员卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "waterCardPassword",
      name: "general.waterCardPassword",
      component: () => import("@/views/general/waterCardPassword/index.vue"),
      meta: {
        title: "卡密管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "smsAccount",
      name: "general.smsAccount",
      component: () => import("@/views/general/smsAccount/index.vue"),
      meta: {
        title: "短信账户",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "waterCardPasswordDistribution",
      name: "general.waterCardPasswordDistribution",
      component: () => import("@/views/general/waterCardPasswordDistribution/index.vue"),
      meta: {
        title: "卡密分销管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
  ],
};
