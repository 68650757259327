// 默认路由G
const defaultRouter = {
  path: "home",
  name: "supplier.home",
  component: () => import("@/views/supplier/home/index.vue"),
  meta: {
    title: "首页",
    auth: {
      required: true,
      header: false,
      list: [],
    },
  },
};
export default {
  default: defaultRouter,
  route: [
    defaultRouter,
    {
      path: "productManage",
      name: "supplier.productManage",
      component: () => import("@/views/supplier/productManage/index.vue"),
      meta: {
        title: "门票产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "analysisChart",
      name: "supplier.analysisChart",
      component: () => import("@/views/supplier/analysisChart/index.vue"),
      meta: {
        title: "数据分析",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "productManageSpecialty",
      name: "supplier.productManageSpecialty",
      component: () => import("@/views/supplier/productManageSpecialty/index.vue"),
      meta: {
        title: "特产管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "logisticsTemplate",
      name: "supplier.logisticsTemplate",
      component: () => import("@/views/supplier/logisticsTemplate/index.vue"),
      meta: {
        title: "物流模板",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageCombination",
      name: "supplier.productManageCombination",
      component: () => import("@/views/supplier/productManageCombination/index.vue"),
      meta: {
        title: "组合商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "productManageLine",
      name: "supplier.productManageLine",
      component: () => import("@/views/supplier/productManageLine/index.vue"),
      meta: {
        title: "线路产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageRestaurant",
      name: "supplier.productManageRestaurant",
      component: () => import("@/views/supplier/productManageRestaurant/index.vue"),
      meta: {
        title: "餐饮产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "fleetManagement",
      name: "supplier.fleetManagement",
      component: () => import("@/views/supplier/fleetManagement/index.vue"),
      meta: {
        title: "车队管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageFleet",
      name: "supplier.productManageFleet",
      component: () => import("@/views/supplier/productManageFleet/index.vue"),
      meta: {
        title: "车队产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productManageHotel",
      name: "supplier.productManageHotel",
      component: () => import("@/views/supplier/productManageHotel/index.vue"),
      meta: {
        title: "酒店产品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productGroup",
      name: "supplier.productGroup",
      component: () => import("@/views/supplier/productGroup/index.vue"),
      meta: {
        title: "产品组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "ticketDocking",
      name: "supplier.ticketDocking",
      component: () => import("@/views/supplier/ticketDocking/index.vue"),
      meta: {
        title: "票务系统对接",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorDocking",
      name: "supplier.distributorDocking",
      component: () => import("@/views/supplier/distributorDocking/index.vue"),
      meta: {
        title: "分销商对接配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "inventoryManage",
      name: "supplier.inventoryManage",
      component: () => import("@/views/supplier/inventoryManage/index.vue"),
      meta: {
        title: "库存管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "inventoryCalendar",
      name: "supplier.inventoryCalendar",
      component: () => import("@/views/supplier/inventoryCalendar/index.vue"),
      meta: {
        title: "库存日历",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleChannel",
      name: "supplier.saleChannel",
      component: () => import("@/views/supplier/saleChannel/index.vue"),
      meta: {
        title: "分销商渠道",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleCodePrice",
      name: "supplier.saleCodePrice",
      component: () => import("@/views/supplier/saleCodePrice/index.vue"),
      meta: {
        title: "销码价格列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "saleCodeCalendar",
      name: "supplier.saleCodeCalendar",
      component: () => import("@/views/supplier/saleCodeCalendar/index.vue"),
      meta: {
        title: "渠道价格日历",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundRule",
      name: "supplier.refundRule",
      component: () => import("@/views/supplier/refundRule/index.vue"),
      meta: {
        title: "退票规则管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "blackWhite",
      name: "supplier.blackWhite",
      component: () => import("@/views/supplier/blackWhite/index.vue"),
      meta: {
        title: "我的黑白名单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "whiteGroup",
      name: "supplier.whiteGroup",
      component: () => import("@/views/supplier/whiteGroup/index.vue"),
      meta: {
        title: "我的白名单组",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "wechatBinding",
      name: "supplier.wechatBinding",
      component: () => import("@/views/supplier/wechatBinding/index.vue"),
      meta: {
        title: "绑定微信",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "receivedMessage",
      name: "supplier.receivedMessage",
      component: () => import("@/views/supplier/receivedMessage/index.vue"),
      meta: {
        title: "已收消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "alreadyMessage",
      name: "supplier.alreadyMessage",
      component: () => import("@/views/supplier/alreadyMessage/index.vue"),
      meta: {
        title: "已发消息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "msgTemplate",
      name: "supplier.msgTemplate",
      component: () => import("@/views/supplier/msgTemplate/index.vue"),
      meta: {
        title: "消息组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "user",
      name: "supplier.user",
      component: () => import("@/views/supplier/user/index.vue"),
      meta: {
        title: "用户列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "invoiceApply",
      name: "supplier.invoiceApply",
      component: () => import("@/views/supplier/invoiceApply/index.vue"),
      meta: {
        title: "发票审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "creditAccount",
      name: "supplier.creditAccount",
      component: () => import("@/views/supplier/creditAccount/index.vue"),
      meta: {
        title: "授信账户管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "creditAccountRecord",
      name: "supplier.creditAccountRecord",
      component: () => import("@/views/supplier/creditAccountRecord/index.vue"),
      meta: {
        title: "授信账户收支",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "transactionRecords",
      name: "supplier.transactionRecords",
      component: () => import("@/views/supplier/transactionRecords/index.vue"),
      meta: {
        title: "交易记录查询",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "youcaiAccountRecord",
      name: "supplier.youcaiAccountRecord",
      component: () => import("@/views/supplier/youcaiAccountRecord/index.vue"),
      meta: {
        title: "账户收支明细",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldOrder",
      name: "supplier.soldOrder",
      component: () => import("@/views/supplier/soldOrder/index.vue"),
      meta: {
        title: "已售订单列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "checkPendingRefundTicket",
      name: "supplier.checkPendingRefundTicket",
      component: () => import("@/views/supplier/checkPendingRefundTicket/index.vue"),
      meta: {
        title: "待审核退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "refundTicket",
      name: "supplier.refundTicket",
      component: () => import("@/views/supplier/refundTicket/index.vue"),
      meta: {
        title: "退票列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "info",
      name: "supplier.info",
      component: () => import("@/views/supplier/info/index.vue"),
      meta: {
        title: "个人信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "smsAccount",
      name: "supplier.smsAccount",
      component: () => import("@/views/supplier/smsAccount/index.vue"),
      meta: {
        title: "短信账户",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "orderSmsTemplate",
      name: "supplier.orderSmsTemplate",
      component: () => import("@/views/supplier/orderSmsTemplate/index.vue"),
      meta: {
        title: "短信模版管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "youcaiAccount",
      name: "supplier.youcaiAccount",
      component: () => import("@/views/supplier/youcaiAccount/index.vue"),
      meta: {
        title: `账户管理`,
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "roleGroup",
      name: "supplier.roleGroup",
      component: () => import("@/views/supplier/roleGroup/index.vue"),
      meta: {
        title: "角色组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "corpInfo",
      name: "supplier.corpInfo",
      component: () => import("@/views/supplier/corpInfo/index.vue"),
      meta: {
        title: "企业信息",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "journal",
      name: "supplier.journal",
      component: () => import("@/views/supplier/journal/index.vue"),
      meta: {
        title: "操作日志",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowSalesOrder",
      name: "supplier.windowSalesOrder",
      component: () => import("@/views/supplier/windowSalesOrder/index.vue"),
      meta: {
        title: "窗口售票订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowMembershipCard",
      name: "supplier.windowMembershipCard",
      component: () => import("@/views/supplier/windowMembershipCard/index.vue"),
      meta: {
        title: "会员卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowUserMembershipCard",
      name: "supplier.windowUserMembershipCard",
      component: () => import("@/views/supplier/windowUserMembershipCard/index.vue"),
      meta: {
        title: "用户会员卡列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowExchangeVIP",
      name: "supplier.windowExchangeVIP",
      component: () => import("@/views/supplier/windowExchangeVIP/index.vue"),
      meta: {
        title: "会员卡兑换",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowRedeemTickets",
      name: "supplier.windowRedeemTickets",
      component: () => import("@/views/supplier/windowRedeemTickets/index"),
      meta: {
        title: "会员兑换门票",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowVipWriteOff",
      name: "supplier.windowVipWriteOff",
      component: () => import("@/views/supplier/windowVipWriteOff/index"),
      meta: {
        title: "会员卡核销列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    }, // {
    //   path: 'windowReport',
    //   name: 'supplier.windowReport',
    //   component: () => import('@/views/supplier/windowReport/index'),
    //   meta: {
    //     title: "景区表报",
    //     auth: {
    //       required: true,
    //       header: false,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowBusinessReport",
      name: "supplier.windowBusinessReport",
      component: () => import("@/views/supplier/windowBusinessReport/index"),
      meta: {
        title: "营业报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketCheckReport",
      name: "supplier.windowTicketCheckReport",
      component: () => import("@/views/supplier/windowTicketCheckReport/index"),
      meta: {
        title: "检票报表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    }, // {
    //   path: 'windowReportForm',
    //   name: 'supplier.windowReportForm',
    //   component: () => import('@/views/supplier/windowReportForm/index'),
    //   meta: {
    //     title: "营业报表模板导出",
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowReportTemplate",
      name: "supplier.windowReportTemplate",
      component: () => import("@/views/supplier/windowReportTemplate/index"),
      meta: {
        title: "报表模板列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelReport",
      name: "supplier.channelReport",
      component: () => import("@/views/supplier/windowReportTemplate/channelReport"),
      meta: {
        title: "渠道报表", // 自定义渠道报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "report",
      name: "supplier.report",
      component: () => import("@/views/supplier/windowReportTemplate/report"),
      meta: {
        title: "营业报表", // 自定义营业报表
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowWriteOff",
      name: "supplier.windowWriteOff",
      component: () => import("@/views/supplier/windowWriteOff/index"),
      meta: {
        title: "核销记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketExchangeTicket",
      name: "supplier.windowTicketExchangeTicket",
      component: () => import("@/views/supplier/windowTicketExchangeTicket/index"),
      meta: {
        title: "核销码兑换门票",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowExchangeRecord",
      name: "supplier.windowExchangeRecord",
      component: () => import("@/views/supplier/windowExchangeRecord/index.vue"),
      meta: {
        title: "会员卡兑换记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowVipExchangeTicket",
      name: "supplier.windowVipExchangeTicket",
      component: () => import("@/views/supplier/windowVipExchangeTicket/index.vue"),
      meta: {
        title: "会员兑换门票记录",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowSalesRefundHistory",
      name: "supplier.windowSalesRefundHistory",
      component: () => import("@/views/supplier/windowSalesRefundHistory/index.vue"),
      meta: {
        title: "退票历史",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    }, // {
    //   path: 'windowSalesRefund',
    //   name: 'supplier.windowSalesRefund',
    //   component: () => import('@/views/supplier/windowSalesRefund/index.vue'),
    //   meta: {
    //     auth: {
    //       required: true,
    //       header: true,
    //       list: [],
    //     }
    //   }
    // },
    {
      path: "windowSales",
      name: "supplier.windowSales",
      component: () => import("@/views/supplier/windowSales/index.vue"),
      meta: {
        title: "窗口售票",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowFace",
      name: "supplier.windowFace",
      component: () => import("@/views/supplier/windowFace/index.vue"),
      meta: {
        title: "人脸机管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowFaceManage",
      name: "supplier.windowFaceManage",
      component: () => import("@/views/supplier/windowFaceManage/index.vue"),
      meta: {
        title: "人脸管理",
        auth: {
          required: true,
          header: false,
          list: [],
        },
      },
    },
    {
      path: "windowTicketingGroup",
      name: "supplier.windowTicketingGroup",
      component: () => import("@/views/supplier/windowTicketingGroup/index.vue"),
      meta: {
        title: "售票组权限管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowGate",
      name: "supplier.windowGate",
      component: () => import("@/views/supplier/windowGate/index.vue"),
      meta: {
        title: "闸机管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowHardware",
      name: "supplier.windowHardware",
      component: () => import("@/views/supplier/windowHardware/index.vue"),
      meta: {
        title: "硬件管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowPrinter",
      name: "supplier.windowPrinter",
      component: () => import("@/views/supplier/windowPrinter/index.vue"),
      meta: {
        title: "打印机管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "windowTicketChecking",
      name: "supplier.windowTicketChecking",
      component: () => import("@/views/supplier/windowTicketChecking/index.vue"),
      meta: {
        title: "检票组管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "uniappCoupon",
      name: "supplier.uniappCoupon",
      component: () => import("@/views/supplier/uniappCoupon/index.vue"),
      meta: {
        title: "优惠券管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorConfig",
      name: "supplier.distributorConfig",
      component: () => import("@/views/supplier/distributorConfig/index.vue"),
      meta: {
        title: "支付配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    } /*******************订单**************************/,
    {
      path: "specialtyOrder",
      name: "supplier.specialtyOrder",
      component: () => import("@/views/supplier/specialtyOrder/index.vue"),
      meta: {
        title: "特产订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldHotelOrder",
      name: "supplier.soldHotelOrder",
      component: () => import("@/views/supplier/soldHotelOrder/index.vue"),
      meta: {
        title: "酒店订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldLineOrder",
      name: "supplier.soldLineOrder",
      component: () => import("@/views/supplier/soldLineOrder/index.vue"),
      meta: {
        title: "",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "paymentChinaums",
      name: "supplier.paymentChinaums",
      component: () => import("@/views/supplier/paymentChinaums/index.vue"),
      meta: {
        title: "银联支付配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldFoodOrder",
      name: "supplier.soldFoodOrder",
      component: () => import("@/views/supplier/soldFoodOrder/index.vue"),
      meta: {
        title: "餐饮订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldFleetOrder",
      name: "supplier.soldFleetOrder",
      component: () => import("@/views/supplier/soldFleetOrder/index.vue"),
      meta: {
        title: "车队订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

    {
      path: "suppllierGoodsCategory",
      name: "supplier.suppllierGoodsCategory",
      component: () => import("@/views/supplier/suppllierGoodsCategory/index.vue"),
      meta: {
        title: "",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "wenchuangGoodsCategory",
      name: "supplier.wenchuangGoodsCategory",
      component: () => import("@/views/supplier/wenchuangGoodsCategory/index.vue"),
      meta: {
        title: "店铺商品分类",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributionConfig",
      name: "supplier.distributionConfig",
      component: () => import("@/views/supplier/distributionConfig/index.vue"),
      meta: {
        title: "分销配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorCity",
      name: "supplier.distributorCity",
      component: () => import("@/views/supplier/distributorCity/index.vue"),
      meta: {
        title: "分销商城",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorsManage",
      name: "supplier.distributorsManage",
      component: () => import("@/views/supplier/distributorsManage/index.vue"),
      meta: {
        title: "分销员配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorAudit",
      name: "supplier.distributorAudit",
      component: () => import("@/views/supplier/distributorAudit/index.vue"),
      meta: {
        title: "分销员审核",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributionCommodityManagement",
      name: "supplier.distributionCommodityManagement",
      component: () => import("@/views/supplier/distributionCommodityManagement/index.vue"),
      meta: {
        title: "分销商品管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "bindings",
      name: "supplier.bindings",
      component: () => import("@/views/supplier/bindings/index.vue"),
      meta: {
        title: "关系绑定",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "applicationChannelVendors",
      name: "supplier.applicationChannelVendors",
      component: () => import("@/views/supplier/applicationChannelVendors/index.vue"),
      meta: {
        title: "申请成为分销商",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributionOrders",
      name: "supplier.distributionOrders",
      component: () => import("@/views/supplier/distributionOrders/index.vue"),
      meta: {
        title: "分销订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "salesOrder",
      name: "supplier.salesOrder",
      component: () => import("@/views/supplier/salesOrder/index.vue"),
      meta: {
        title: "销售订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "withdrawalAudit",
      name: "supplier.withdrawalAudit",
      component: () => import("@/views/supplier/withdrawalAudit/index.vue"),
      meta: {
        title: "提现审核列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "withdrawal",
      name: "supplier.withdrawal",
      component: () => import("@/views/supplier/withdrawal/index.vue"),
      meta: {
        title: "提现配置",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "paymentList",
      name: "supplier.paymentList",
      component: () => import("@/views/supplier/paymentList/index.vue"),
      meta: {
        title: "打款列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelCodeCalendar",
      name: "supplier.channelCodeCalendar",
      component: () => import("@/views/supplier/channelCodeCalendar/index.vue"),
      meta: {
        title: "分销商品价格日历",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "productveue",
      name: "supplier.productvenue",
      component: () => import("@/views/supplier/productvenue/index.vue"),
      meta: {
        title: "场馆管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "soldVenueOrder",
      name: "supplier.soldVenueOrder",
      component: () => import("@/views/supplier/soldVenueOrder/index.vue"),
      meta: {
        title: "场馆订单",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "channelApply",
      name: "supplier.channelApply",
      component: () => import("@/views/supplier/channelApply/index.vue"),
      meta: {
        title: "申请渠道",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "disSaleCodeInfo",
      name: "supplier.disSaleCodeInfo",
      component: () => import("@/views/supplier/disSaleCodeInfo/index.vue"),
      meta: {
        title: "供应商渠道码",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "distributorsApply",
      name: "supplier.distributorsApply",
      component: () => import("@/views/supplier/distributorsApply/index.vue"),
      meta: {
        title: "申请列表",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "combinationgoods",
      name: "supplier.combinationgoods",
      component: () => import("@/views/supplier/combinationGoods/index.vue"),
      meta: {
        title: "组合商品",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "unionPaySigning",
      name: "supplier.unionPaySigning",
      component: () => import("@/views/supplier/unionPaySigning/index.vue"),
      meta: {
        title: "银联签约",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "supplierDetail",
      name: "supplier.supplierDetail",
      component: () => import("@/views/supplier/supplierDetail/supplierDetail.vue"),
      meta: {
        title: "店铺详情",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },
    {
      path: "waterCardOrder",
      name: "supplier.waterCardOrder",
      component: () => import("@/views/supplier/waterCardOrder/index.vue"),
      meta: {
        title: "亲水季会员卡管理",
        auth: {
          required: true,
          header: true,
          list: [],
        },
      },
    },

  ],
};
