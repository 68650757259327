<template>
  <div class="appPicture">
    <div class="uploadType">
      <!--            <el-radio-group v-model="uploadType">-->
      <!--                <el-radio label="default">默认上传</el-radio>-->
      <!--                <el-radio label="oss">OSS上传</el-radio>-->
      <!--            </el-radio-group>-->
    </div>
    <div class="appPicture-list">
      <div class="appPicture-item" v-for="(item, index) in list" :key="index">
        <appPicture-image
            class="appPicture-item-image"
            :disabled="disabled"
            :uploadType="uploadType"
            v-model="list[index]"
            @del="del(index)"

        ></appPicture-image>
      </div>
      <label class="appPicture-button" v-if="list.length < max && !disabled">
        <div class="appPicture-button-icon">
          <app-icon name="add"/>
        </div>
        <div class="appPicture-button-text">选择图片</div>
        <form ref="clearTool">
          <input type="file" @change="add($event)" :multiple="max > 1"/>
        </form>
      </label>
    </div>
  </div>
</template>

<script>
import appPictureImage from "./appPicture-image.vue";

export default {
  components: {
    appPictureImage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    uploadType() {
      this.list = [];
    },
    modelValue: {
      deep: true,
      handler(modelValue) {
        const sortModelValue = JSON.parse(JSON.stringify(modelValue));
        const list = JSON.parse(
            JSON.stringify(this.list.map((item) => item.url))
        );
        if (
            JSON.stringify(sortModelValue.sort()) != JSON.stringify(list.sort())
        ) {
          this.list = modelValue.map((item) => {
            return {
              url: item,
            };
          });
        }
      },
    },
    list: {
      deep: true,
      handler(list) {
        this.$emit(
            "update:modelValue",
            list.map((item) => item.url)
        );
      },
    },
  },
  data() {
    return {
      uploadType: "default",
      list: [],
    };
  },
  methods: {

    /**
     * 添加图片
     */
    async add(e) {
      const imgs = [];
      // 获取循环长度
      const len = Math.min(this.max - this.list.length, e.target.files.length);
      for (let index = 0; index < len; index++) {
        if (e.target.files[index].size > 20480000) {
          this.$message.error('请上传小于20M的图片');
          return false
        }
        imgs.push({
          image: e.target.files[index],
          url: "",
        });
      }
      this.list = this.list.concat(imgs);
      // 清空
      this.$refs.clearTool.reset();
    },
    /**
     * 移除
     */
    del(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.appPicture {
  // display: flex;
  // gap: 10px;

  &-button {
    width: 100px;
    height: 100px;
    border: 1px solid color.$border;
    border-radius: 4px;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-icon {
      color: color.$base;
      width: 42px;
      height: 42px;
      background: color.$background;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-text {
      color: color.$inputText;
      font-size: 14px;
      line-height: 14px;
      margin-top: 10px;
    }

    form {
      display: none;
    }
  }

  &-list {
    display: flex;
    gap: 10px;
  }

  &-item {
    width: 100px;
    height: 100px;
    border: 1px solid #ecebef;
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>
