<template>
  <div class="app">
    <!-- 左侧 -->
    <div class="app-leftview" :class="{ 'app-leftview_show': menuSlide }">
      <div class="app-leftview-content">
        <app-menu class="app-menu" ref="appMenu"></app-menu>
        <app-menu-mini class="app-menu-mini"></app-menu-mini>
      </div>
    </div>
    <!-- 右侧 -->
    <div class="app-rightview">
      <app-header-tag class="app-nav" @selected="selected" @handleSelectClick="handleSelectClick"></app-header-tag>
      <app-header class="app-header"></app-header>
      <app-content class="app-content"></app-content>
    </div>
    <!-- 全民分销授权弹窗 -->
    <el-dialog v-model="distributionShow" title="开通渠道分销" :append-to-body="true" width="500" align-center>
      <main v-if="resultShow">
        <el-form ref="formRef" :model="distributionFormData" :rules="rules" label-width="auto" label-position="left">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="distributionFormData.enterpriseName" :disabled="true" placeholder="请输入企业名称" />
          </el-form-item>
          <el-form-item label="联系人" prop="username">
            <el-input v-model="distributionFormData.username" placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile">
            <el-input v-model="distributionFormData.mobile" placeholder="请输入联系电话" />
          </el-form-item>
        </el-form>
      </main>
      <template #footer v-if="resultShow">
        <span class="dialog-footer">
          <el-button @click="resultShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm" :loading="loadingBtn"> 确认 </el-button>
        </span>
      </template>
      <Transition enterActiveClass="animate__animated animate__fadeIn">
        <el-result v-if="!resultShow" icon="success" title="申请成功" sub-title="点击确认按钮，进入分销商中心">
          <template #extra>
            <el-button type="primary" @click="$router.push({ name: 'supplier.distributorCity' })">进入分销中心</el-button>
          </template>
        </el-result>
      </Transition>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import appMenu from "@/components/app-menu";
import appMenuMini from "@/components/app-menu-mini";
import appHeader from "@/components/app-header";
import appContent from "@/components/app-content";
import appHeaderTag from "@/components/app-header-tag";
import { ElMessage } from "element-plus";

export default {
  name: "index",
  components: {
    appMenu,
    appMenuMini,
    appHeader,
    appContent,
    appHeaderTag,
  },
  computed: {
    ...mapState("app", ["menuSlide"]),
  },
  data() {
    return {
      distributionShow: false,
      resultShow: true,
      loadingBtn: false,
      distributionFormData: {
        id: "",
        enterpriseName: "",
        username: "",
        mobile: "",
      },
      rules: {
        username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
    };
  },
  mounted() {
    const corpInfo = JSON.parse(localStorage.getItem("userInfo")).corp;
    this.distributionFormData.enterpriseName = corpInfo?.corpname;
  },
  methods: {
    async submitForm() {
      try {
        this.loadingBtn = true;
        const result = await this.$request.post("/supplier/distributor/adminApplyFor", {
          id: this.distributionFormData.id,
          value: {
            username: this.distributionFormData.username,
            mobile: this.distributionFormData.mobile,
          },
        });
        this.resultShow = false;
        this.loadingBtn = false;
        this.distributionShow = false;
        ElMessage.success(result.message);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingBtn = false;
      }
    },
    selected(e) {
      this.$refs.appMenu.changemenuname(e);
    },
    async handleSelectClick(e) {
      if (e.name == "全民分销" && !(await this.isDis())) {
        this.distributionShow = true;
        return;
      }
      this.$refs.appMenu.changemenunameClick(e);
    },

    async isDis() {
      try {
        const result = await this.$request.get("/supplier/distributor/isDIs");
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  height: 100vh;
  width: 100vw;
  display: flex;

  &-leftview {
    width: 56px;
    height: 100%;
    transition: all 500ms;
    position: relative;
    overflow: hidden;
    // z-index: 21;
    z-index: 10;

    &_show {
      width: 240px;
    }

    &-content {
      width: calc(240px + 56px);
      display: flex;
      transform: translateX(-240px);
      transition: 500ms transform;
      height: 100%;
    }

    &_show &-content {
      transform: translateX(0%);
    }
  }

  &-rightview {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 11;
  }

  &-menu {
    height: 100%;
    user-select: none;
  }

  &-nav {
    height: 66px;
  }

  &-header {
    height: 55px;
  }

  &-content {
    flex: 1;
    height: 0;
  }
}
</style>
